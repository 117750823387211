<template>
  <div class="page">
    <div v-show="this.loading">
      <div class="title-box">
        <!-- <span class="sel">日期</span> -->
        <el-date-picker
          style="width: 170px"
          type="date"
          format="YYYY 年 MM 月 DD 日"
          value-format="YYYY-MM-DD"
          v-model="dateTime"
          placeholder="轨迹时间"
        >
        </el-date-picker>
        <el-button type="primary" style="margin-left: 5px" @click="searchDate"
          >查询</el-button
        >
        <el-button type="primary" style="margin-left: 5px" @click="open">{{
          openString
        }}</el-button>
        <el-button type="primary" style="margin-left: 5px" @click="play"
          >播放</el-button
        >
        <span style="margin-left: 20px; color: red"
          >轨迹数量:{{ traceNum }}</span
        >
      </div>

      <el-collapse-transition>
        <div class="trace-box" v-show="traceShow">
          <div>
            <div
              style="border-radius: 5px;
                background-color: #285df0;
                color: white;
                font-size: 18px;
                height: 50px;
                padding-left: 10px;
                padding-top: 20px;
              "
            >
              设备ID:{{ this.deviceId }}
            </div>
            <el-table
              :data="sgDataList"
              :show-header="false"
              style="
                width: 100%;
                border-radius: 5px;
                max-height: 520px;
                overflow-y: scroll;
              "
              :header-cell-style="{ background: '#285df0'}"
              :empty-text="emptyText1"
            >
              <el-table-column
                prop="createTime"
                label="时间/方式"
                label-class-name="tablehead"
                align="center "
              >
                <template #default="scope">
                  <span>{{ scope.row.createTime.substring(10, 16) }}</span>
                  <span v-if="scope.row.mode == 0">(GPS)</span>
                  <span v-if="scope.row.mode == 1">(LBS)</span>
                  <span v-if="scope.row.mode == 2">(WIFI)</span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                label-class-name="tablehead"
                align="center "
                width="80"
              >
                <template #default="scope">
                  <span class="textbtn" @click="seeAddress(scope.row)"
                    >详情</span
                  >
                </template>
              </el-table-column>
            </el-table>

            <!-- <div class="pagination">
              <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" layout="prev, pager, next" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
            </div> -->
          </div>
        </div>
      </el-collapse-transition>
    </div>

    <div class="box">
      <div id="map" class="amap-wrapper"></div>
    </div>
  </div>
</template>

<script>
import { 
  getSgInfos,
  getWearInfos
} from "../../../api/api";
import session from "../../../store/store";
import mapInfo from "./js/map-info";
import AMap from "AMap"; // 引入高德地图
export default {
  data() {
    return {
      layout: "prev, pager, next",
      emptyText1: "暂无数据",
      openString: "收起",
      traceShow: true,
      loading: true,
      dateTime: "",
      infoWindow: null, //窗体
      map: null, //地图实例对象
      iconBlue: null,
      markerMove: null,
      passedPolyline:null,
      lineArray: [],
      dataList: [],
      sgDataList: [],
      sgData: {},
      deviceData: {},
      traceNum: 0,
      deviceId: "",
      marker: null,
      role:0,
    };
  },
  mounted() {
    this.initMap();
    this.init();
  },
  unmounted() {
    if(this.map != null) {
      this.map.destroy()
      this.map = null
    }
  },
  methods: {
    init() {
      var date = new Date();
      var year = date.getFullYear(); //  返回的是年份
      var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
      var day = date.getDate(); //  返回的是几号

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      this.dateTime = year + "-" + month + "-" + day;

      this.role = session.getLoginInfo().accountType;
      this.getWearInfos();

    },
    getWearInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
        page:0,
        size:1,
        idcard:session.getCustomerData().idcard
      }
      getWearInfos(param).then(res => {
        if (res.data.code == 200) {
         var deviceList = res.data.dataInfo
         this.deviceData = deviceList[0]
         this.deviceId = this.deviceData.deviceId
         this.getSgInfos()
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    searchDate() {
      this.getSgInfos();
    },

    getSgInfos() {
      let param = {
        deviceId: this.deviceId,
        page: 0,
        size: 200,
        startTime: this.dateTime,
        endTime: this.dateTime,
      };
      getSgInfos(param).then((res) => {
        if (res.data.code == 200) {
          this.traceNum = res.data.count;
          this.sgDataList = res.data.dataInfo;
          this.getLatLng();
        } else {
          this.traceNum = 0;
          this.sgDataList.splice(0, this.sgDataList.length);
        }
      });
    },

    getLatLng() {
      this.lineArray.splice(0, this.lineArray.length);
      this.sgDataList.forEach((data) => {
        this.lineArray.push(new AMap.LngLat(data.lng, data.lat));
      });
  
      this.initPolyline();
    },


    /**
     * 地图初始化
     */
    initMap() {
      this.map = new AMap.Map("map", {
        resizeEnable: true,
        zoom: 17,
        pitch: 50,
        // viewMode: '3D', //开启3D视图,默认为关闭
      });
      // 信息窗口实例
      this.infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(7, 0),
      });
      // this.markerMove = new AMap.Marker({
      //   map: this.map,
      //   icon: require("../../../assets/img/car.png"),
      //   offset: new AMap.Pixel(-13, -26), //相对于基点的位置
      // });

      this.iconBlue = new AMap.Icon({
        image: require("../../../assets/img/point-blue.png"),
        size: new AMap.Size(14, 23),
      });
    },

    initPolyline() {
        this.map.clearMap();

        // this.markerMove = new AMap.Marker({
        //   map: this.map,
        //   icon: require("../../../assets/img/car.png"),
        //   offset: new AMap.Pixel(-13, -26), //相对于基点的位置
        // });

        // if (this.lineArray.length > 0) {
        //   this.markerMove.setPosition(this.lineArray[0]);
        // }
        // this.map.add(this.markerMove);
        //绘制轨迹
        var polyline = new AMap.Polyline({
          map: this.map,
          path: this.lineArray,
          showDir: true,
          strokeColor: "red", //线颜色28F
          strokeWeight: 5, //线宽
        });

        this.map.add(polyline);

        //绘制移动过去后的轨迹
        this.passedPolyline = new AMap.Polyline({
          map: this.map,
          strokeColor: "#28F", //线颜色AF5
          strokeWeight: 5, //线宽
        });

        this.map.add(this.passedPolyline);

        //小车移动过的轨迹变色
        // this.markerMove.on("moving", (e) => {
        //   passedPolyline.setPath(e.passedPath);
        //   this.map.setCenter(e.target.getPosition(), true);
        // });
        this.map.setFitView(polyline,true);

    },
    seeAddress(row) {
      this.sgData = row;
      if (0 == this.sgData.mode) {
        this.sgData.modeName = "GPS";
      } else if (1 == this.sgData.mode) {
        this.sgData.modeName = "LBS";
      } else {
        this.sgData.modeName = "WIFI";
      }
      this.addMarker();
    },
    addMarker() {
      //实时路况图层
      if (null != this.marker) {
        this.map.remove(this.marker);
      }

      this.marker = new AMap.Marker({
        position: new AMap.LngLat(this.sgData.lng, this.sgData.lat), //位置
        icon: this.iconBlue, // 添加 Icon 实例
      });
      this.map.add(this.marker); //添加到地图

      this.infoWindow.setContent(mapInfo.getHtml(this.deviceData, this.sgData));
      this.marker.on("click", () => {
        if (!this.infoWindow.getIsOpen()) {
          this.infoWindow.open(this.map, this.marker.getPosition());
        } else {
          this.infoWindow.close();
        }
      });

      this.infoWindow.open(this.map, this.marker.getPosition());
      this.map.setFitView(this.marker,true);
    },

    play() {
      if (this.sgDataList.length == 0) {
        this.$message.error({ message: "暂无轨迹数据" });
        return;
      }
      if (null != this.markerMove) {
        this.map.remove(this.markerMove);
      }
      this.markerMove = new AMap.Marker({
        map: this.map,
        icon: require("../../../assets/img/car.png"),
        offset: new AMap.Pixel(-13, -26), //相对于基点的位置
      });
      if (this.lineArray.length > 0) {
        this.markerMove.setPosition(this.lineArray[0])
      }
      this.map.add(this.markerMove);
      AMap.plugin("AMap.MoveAnimation", () => {
        this.markerMove.moveAlong(this.lineArray, {
          duration: 500,
          autoRotation: true,
        });
      });

      //小车移动过的轨迹变色
      this.markerMove.on("moving", (e) => {
        this.passedPolyline.setPath(e.passedPath);
        this.map.setCenter(e.target.getPosition(), true);
      });
    },
 
    open() {
      this.traceShow = !this.traceShow;
      if (this.traceShow) {
        this.openString = "收起";
      } else {
        this.openString = "展开";
      }
    },

  },
};
</script>

<style scoped>
.device-box {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
  background-color: white;
  /* padding: 9px; */
  border-radius: 8px;
  width: 330px;
  /* height: 500px; */
}

.device-box-top {
  width: 100%;
  height: 60px;
  background-color: #007ffe;
  border-radius: 8px 8px 0px 0px;
  /* display: flex;
  align-items: center; */
}

.device-title {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 18px;
}

.box {
  width: 100%;
  height: 100%;
  /* height: 900px; */
  position: fixed;
}
.amap-wrapper {
  width: 100%;
  height: 100%;
}

.title-box {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  background-color: white;
  padding: 4px;
  border-radius: 8px;
  width: 520px;
}

.trace-box {
  position: absolute;
  top: 80px;
  left: 20px;
  z-index: 1;
  background-color: white;
  /* padding: 9px; */
  /* padding: 4px; */
  border-radius: 5px;
  width: 270px;
  /* max-height: 500px; */
  /* height: 500px; */
}

.textbtn {
  color: #285df0;
  cursor: pointer;
}
</style>
<style>
.trace-box .el-table__row > td {
  border: none;
}
.trace-box .el-table::before {
  height: 0px;
}
.tablehead {
  color: white;
}
.amap-info-content {
  overflow: hidden;
  padding: 0px;
}
</style>